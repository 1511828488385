import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import BASE_URL from "../variables.js";
import { toast } from "react-toastify";

import LoadingGif from "../assets/loading.gif";
import Picker from "./picker.jsx";
import moment from "moment-jalaali";

function Technical() {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);

  const [showDate, setShowDate] = useState("");
  const [requestedDate, setRequestedDate] = useState();
  const isFirstRender = useRef(true);

  // const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/board/lastDate`)
      .then((response) => {
        setShowDate(response.data.date);
        setRequestedDate(response.data.date);
      })
      .catch((error) => {
        toast("مشکلی در تاریخ پیش آمد");
      });
    axios
      .get(`${BASE_URL}/api/tech/getAll`)
      .then((response) => {
        setAnalysis(response.data);
      })
      .catch((error) => {
        toast("مشکلی در بارگیری تحلیل آخرین تاریخ پیش آمد");
      });
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (requestedDate) {
      setLoading(true);
      const formattedDate = requestedDate.replace(
        /(\d{4})(\d{2})(\d{2})/,
        "$1-$2-$3"
      );
      axios
        .get(`${BASE_URL}/api/tech/getAll?date=${formattedDate}`)
        .then((response) => {
          setAnalysis(response.data);
          setLoading(false);
        })
        .catch((error) => {
          toast("مشکلی در تاریخ جدید پیش آمد. ");
          setLoading(false);
        });
    }
  }, [requestedDate]);

  return (
    <div>
      {/* <p
        style={{
          display: "inline",
          fontWeight: "bold",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        تاریخ امتیازدهی: {showDate}
      </p> */}

      {requestedDate && (
        <div style={{ marginBottom: "30px" }}>
          <Picker currentDate={showDate} setter={setRequestedDate} />
        </div>
      )}

      <div className="tablesContainer">
        <table className="commonTable" style={{ color: "black" }}>
          <thead>
            <tr style={{ backgroundColor: "white", color: "black" }}>
              <th>سهم</th>
              <th>RSI</th>
              <th>میانگین متحرک</th>
              <th>خط روند</th>
              {/* <th>مومنتوم</th> */}
              <th>کندل‌شناسی</th>
              <th>امتیاز</th>
            </tr>
          </thead>
          <tbody>
            {analysis && !loading ? (
              analysis.map((item, index) => {
                const dynamicKey = Object.keys(item).find(
                  (key) =>
                    key !== "stockTitle" &&
                    key !== "rsi" &&
                    key !== "ma" &&
                    key !== "trendline" &&
                    key !== "sum" &&
                    key !== "momentum"
                );
                return (
                  <tr key={index}>
                    <td style={{ fontWeight: "bold" }}>{item.stockTitle}</td>
                    <td>{item.rsi ? (item.rsi > 0 ? "خوب" : "بد") : "-"}</td>
                    <td>{item.ma > 0 ? "خوب" : "بد"}</td>
                    <td>
                      {item.trendline
                        ? item.trendline > 0
                          ? "خوب"
                          : "بد"
                        : "-"}
                    </td>
                    {/* <td>
                      {item.momentum ? (item.momentum > 0 ? "خوب" : "بد") : "-"}
                    </td> */}
                    <td>{dynamicKey ? dynamicKey : "-"}</td>
                    <td>{item.sum}</td>
                  </tr>
                );
              })
            ) : (
              <tr style={{ border: "none" }}>
                <td colSpan={8}>
                  <img src={LoadingGif} className="loadingGif" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Technical;
