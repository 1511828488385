import React, { useState, useEffect } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlassChart,
  FaBars,
} from "@fortawesome/free-solid-svg-icons";

// icons from fontawesome 5
function SideNavbar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  // function handleLogout() {
  //   localStorage.removeItem("userName");
  //   localStorage.removeItem("token");
  //   // window.location = "/";
  // }

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <nav className="mobile-navbar">
          <ul className="nav-list">
            <li>
              <NavLink
                to="/servicesPage"
                end
                className={({ isActive }) => (isActive ? "active" : "")}
              
              >
                داشبورد
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/servicesPage/predict"
                className={({ isActive }) =>
                  isActive ? "active" : ""
                }
              >
                پیش‌بینی قیمت
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/servicesPage/board"
                className={({ isActive }) =>
                  isActive ? "active" : ""
                }
              >
                تابلوخوانی{" "}
              </NavLink>
              <NavLink
                to="/servicesPage/fundamental"
                className={({ isActive }) =>
                  isActive ? "active" : ""
                }
              >
                بنیادی{" "}
              </NavLink>
              <NavLink
                to="/servicesPage/technical"
                className={({ isActive }) =>
                  isActive ? "active" : ""
                }
              >
                تکنیکال{" "}
              </NavLink>
              <NavLink
                to="/servicesPage/didehban"
                className={({ isActive }) =>
                  isActive ? "active" : ""
                }
                style={{marginLeft:'10px'}}
              >
                دیده‌بان{" "}
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : (
        <CDBSidebar id="profileSidebar" toggled>
          <CDBSidebarHeader
            prefix={
              <div>
                <FontAwesomeIcon icon={faMagnifyingGlassChart} size="lg" />

                {/* <i
                className="fa fa-search-dollar fa-large"
                onClick={() => setIsCollapsed(!isCollapsed)}
              ></i> */}
              </div>
            }
          />
          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <NavLink
                exact
                to="/servicesPage"
                className={`${
                  location.pathname === "/servicesPage" ? "activeClicked" : ""
                }`}
              >
                <CDBSidebarMenuItem icon="home">داشبورد</CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact
                to="/servicesPage/predict"
                className={(navData) =>
                  navData.isActive ? "activeClicked" : ""
                }
              >
                <CDBSidebarMenuItem icon="chart-line">
                  پیش‌بینی قیمت سهام
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/servicesPage/board"
                className={(navData) =>
                  navData.isActive ? "activeClicked" : ""
                }
              >
                <CDBSidebarMenuItem icon="chart-bar">
                  تابلوخوانی
                </CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/servicesPage/fundamental"
                className={(navData) =>
                  navData.isActive ? "activeClicked" : ""
                }
                // className="disabled-item"
              >
                <CDBSidebarMenuItem icon="book">بنیادی</CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/servicesPage/technical"
                className={(navData) =>
                  navData.isActive ? "activeClicked" : ""
                }
                // className="disabled-item"
              >
                <CDBSidebarMenuItem icon="ruler">تکنیکال</CDBSidebarMenuItem>
              </NavLink>

              <NavLink
                exact
                to="/servicesPage/didehban"
                className={(navData) =>
                  navData.isActive ? "activeClicked" : ""
                }
              >
                <CDBSidebarMenuItem icon="eye">دیده‌بان</CDBSidebarMenuItem>
              </NavLink>

              {/* <NavLink
            exact
            to="/"
            // className={(navData) => (navData.isActive ? "activeClicked" : "")}
            onClick={handleLogout}
          >
            <CDBSidebarMenuItem icon="power-off">خروج</CDBSidebarMenuItem>
          </NavLink> */}
            </CDBSidebarMenu>
          </CDBSidebarContent>
        </CDBSidebar>
      )}
    </>
  );
}

export default SideNavbar;
