import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../variables.js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import loadingGif from "../assets/loading.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faClipboardCheck,
  faCircleXmark,
  faDownload,
  faStar as faSolidStar,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons";

function StockDetails(props) {
  const { stockName } = useParams();

  const [wholeAth, setWholeAth] = useState(null);
  const [predict, setPredict] = useState(null);
  const [wholeBoard, setWholeBoard] = useState(null);
  const [reports, setReports] = useState();
  const [analysis, setAnalysis] = useState(null);

  // const token = localStorage.getItem("token");

  const [enablePredict, setEnablePredict] = useState(0);

  useEffect(() => {
    setWholeAth(null);
    setPredict(null);
    setWholeBoard(null);
    setReports();
    setAnalysis(null);

    setEnablePredict(0);
    // ////////////ATH
    axios
      .get(`${BASE_URL}/api/ath/getAll?nameArg=${stockName}`)
      .then((response) => {
        setWholeAth(
          response.data.filter((item) => item.stockTitle === stockName)
        );
      })
      .catch((error) => {
        toast("مشکلی در ATH پیش آمد");
      });
    // /////////Predict
    axios
      .get(`${BASE_URL}/api/predict/stockPredict?stockName=${stockName}`)
      .then((response) => {
        setPredict(response.data);
        setEnablePredict(1);
      })
      .catch((error) => {
        if (error.response.status == 404) {
          setEnablePredict(0);
        } else {
          toast("مشکلی در پیش‌بینی پیش آمد. ");
        }
      });
    // ////////TABLO
    axios
      .get(`${BASE_URL}/api/board/lastDays?nameArg=${stockName}`)
      .then((response) => {
        setWholeBoard(
          response.data.filter((item) => item.stockTitle === stockName)
        );
      })
      .catch((error) => {
        toast("مشکلی در تابلو پیش آمد. ");
      });
    // ////////////FUNDAMENTAL
    axios
      .get(`${BASE_URL}/api/cp/getpaginated?nameArg=${stockName}`)
      .then((response) => {
        setReports(
          response.data.stocks.filter((item) => item.symbol === stockName)
        );
        // console.log(response.data);
      })
      .catch((error) => {
        toast("مشکلی در بنیادی پیش آمد");
      });
    // ////////TECHNICAL
    axios
      .get(`${BASE_URL}/api/tech/getAll?nameArg=${stockName}`)
      .then((response) => {
        setAnalysis(response.data);
      })
      .catch((error) => {
        toast("مشکلی در تکنیکال پیش آمد. ");
      });
  }, [stockName]);

  // const handleAddFavorite = () => {
  //   axios
  //     .post(
  //       `${BASE_URL}/addStockToFavoriteList`,
  //       {
  //         stockCode: stockName,
  //       },
  //       {
  //         headers: {
  //           authorization: token,
  //         },
  //       }
  //     )
  //     .then(() => {
  //       if (!props.favors) {
  //         props.setter([stockName]);
  //       } else {
  //         props.setter([...props.favors, stockName]);
  //       }
  //     })
  //     .catch((error) => {
  //       toast("مشکلی در افزودن به مورد علاقه‌ها پیش آمد");
  //       // console.log(error);
  //     });
  // };
  // const handleDeleteFavorite = () => {
  //   axios
  //     .post(
  //       `${BASE_URL}/removeStockFromFavoriteList`,
  //       {
  //         stockCode: stockName,
  //       },
  //       {
  //         headers: {
  //           authorization: token,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       props.setter(props.favors.filter((item) => item != stockName));
  //     })
  //     .catch((error) => {
  //       toast("مشکلی در حذف مورد علاقه پیش آمد");
  //     });
  // };

  return (
    <div id="detailsPage">
      <h1 style={{ display: "inline", margin: "10px" }}>{stockName}</h1>
      {/* {props.favors && props.favors.includes(stockName) ? (
        <FontAwesomeIcon
          icon={faSolidStar}
          style={{ color: "#FFBF00", cursor: "pointer" }}
          size="2x"
          onClick={handleDeleteFavorite}
        />
      ) : (
        <FontAwesomeIcon
          icon={faRegularStar}
          style={{ color: "#FFBF00", cursor: "pointer" }}
          size="2x"
          onClick={handleAddFavorite}
        />
      )} */}

      <hr />
      <div>
        <div className="tablesContainer">
          <table className="commonTable" id="athTable">
            <thead>
              <tr>
                <th>رتبه</th>
                <th>سهم</th>
                <th>قیمت (تومان)</th>
                <th>سقف تاریخی (تومان)</th>
                {/* <th>فاصله تا کف تاریخی %</th> */}
                <th>افت از سقف تاریخی %</th>
                <th>فاصله تا سقف تاریخی %</th>
              </tr>
            </thead>

            <tbody>
              {wholeAth ? (
                wholeAth.map((item, index) => (
                  <tr
                    key={index}
                    style={
                      item.to_ath >= 100
                        ? { color: "#0B6623" }
                        : item.to_ath >= 50
                        ? { color: "#0066b2" }
                        : item.to_ath >= 0
                        ? { color: "#8cc73c" }
                        : { color: "red" }
                    }
                  >
                    <td style={{ fontWeight: "bold" }}>{item.index}</td>
                    <td style={{ fontWeight: "bold" }}>{item.stockTitle}</td>
                    <td>{(item.price / 10).toFixed(0)}</td>
                    <td>{(item.ath_price / 10).toFixed(0)}</td>
                    {/* <td>{item.to_atl.toFixed(0)}</td> */}
                    <td>{item.from_ath.toFixed(0)}</td>
                    <td style={{ fontWeight: "bold" }}>
                      {item.to_ath.toFixed(0)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ border: "none" }}>
                  <td colSpan={6}>
                    <img src={loadingGif} className="loadingGif" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div>
        <h2 style={{ color: "#334456" }}>پیش‌بینی</h2>
        {enablePredict ? (
          <div id="detailsPagePredict">
            <div>
              <div>هفتگی</div>
              <div className="tablesContainer">
                <table className="commonTable" id="predictTable">
                  <thead>
                    <tr>
                      <th>روند تا هفتۀ آینده</th>
                      <th>احتمال</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {predict.weekly.label == 2
                          ? "صعودی"
                          : predict.weekly.label == 1
                          ? "رنج"
                          : "نزولی"}
                      </td>
                      <td>{predict.weekly.confidence.toFixed(1)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              style={{
                borderRight: "solid 1px black",
                borderLeft: "solid 1px black",
              }}
            >
              <div>ماهانه</div>
              <div
                className="tablesContainer"
                style={{ height: "fit-content" }}
              >
                <table className="commonTable" id="predictTable">
                  <thead>
                    <tr>
                      <th>روند تا ماه آینده</th>
                      <th>احتمال</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {predict.monthly.label == 2
                          ? "صعودی"
                          : predict.monthly.label == 1
                          ? "رنج"
                          : "نزولی"}
                      </td>
                      <td>{predict.monthly.confidence.toFixed(1) - 5}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div>نوسانگیر</div>
              <div
                className="tablesContainer"
                style={{ height: "fit-content" }}
              >
                <table className="commonTable" id="predictTable">
                  <thead>
                    <tr>
                      <th>نوسان</th>
                      <th>احتمال</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {predict.osc.label == 2
                          ? "مثبت"
                          : predict.osc.label == 1
                          ? "خنثی"
                          : "منفی"}
                      </td>
                      <td>{predict.osc.confidence.toFixed(1)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <p>به علت کمبود منابع سرور، پیش‌بینی این نماد در دسترس نمی‌باشد.</p>
        )}
      </div>
      <hr />
      <div>
        <h2 style={{ color: "#e31228" }}>تابلوخوانی</h2>
        <div className="tablesContainer">
          <table className="commonTable" id="boardTable">
            <thead>
              <tr style={{ backgroundColor: "red" }}>
                <th>تاریخ</th>
                <th>سهم</th>
                <th>حجم مشکوک</th>
                <th>پول هوشمند</th>
                <th>پول حقیقی</th>
                <th>پایانی به آخرین</th>
                <th>قدرت خریدار</th>
                <th>امتیاز</th>
              </tr>
            </thead>
            <tbody>
              {wholeBoard ? (
                wholeBoard.map((item, index) => (
                  <tr
                    key={index}
                    style={
                      item.sum >= 75
                        ? { backgroundColor: "#0B6623" }
                        : item.sum >= 50
                        ? { backgroundColor: "#8cc73c" }
                        : item.sum >= 25
                        ? { backgroundColor: "#87CEEB" }
                        : item.sum >= -25
                        ? { backgroundColor: "#808080" }
                        : item.sum >= -50
                        ? { backgroundColor: "#9b870c" }
                        : item.sum >= -75
                        ? { backgroundColor: "orange" }
                        : { backgroundColor: "red" }
                    }
                  >
                    <td style={{ fontWeight: "bold" }}>{item.date}</td>
                    <td style={{ fontWeight: "bold" }}>{item.stockTitle}</td>
                    <td>{item.suspicios_volume}</td>
                    <td>{item.intel_money}</td>
                    <td>{item.real_money}</td>
                    <td>{item.final_last}</td>
                    <td>{item.buy_power}</td>
                    <td style={{ fontWeight: "bold" }}>{item.sum}</td>
                  </tr>
                ))
              ) : (
                <tr style={{ border: "none" }}>
                  <td colSpan={6}>
                    <img src={loadingGif} className="loadingGif" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div>
        <h2 style={{ color: "#00755e" }}>بنیادی</h2>
        <div className="tablesContainer" style={{ height: "300px" }}>
          <table className="commonTable" id="fundamentalTable">
            <thead>
              <tr>
                <th>تاریخ گزارش</th>
                <th>سهم</th>
                <th>نوع گزارش</th>
                <th>اهمیت</th>
                <th>دوره (ماه)</th>
                <th>وضعیت حسابرسی</th>
                <th>تلفیقی</th>
                <th>مقدار در گزارش قبلی</th>
                <th>مقدار در گزارش فعلی</th>
                <th>دانلود گزارش</th>
              </tr>
            </thead>
            <tbody>
              {reports ? (
                reports.length == 0 ? (
                  <tr>
                    <td colSpan={6}>گزارشی برای این نماد وجود ندارد</td>
                  </tr>
                ) : (
                  reports.map((dates, index) => (
                    <React.Fragment key={index}>
                      {dates.fundamental.map((item, indexReport) => (
                        <tr key={indexReport}>
                          {indexReport == 0 ? (
                            <td
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              rowspan={dates.fundamental.length}
                            >
                              {dates.date.slice(0, 4)}/{dates.date.slice(4, 6)}/
                              {dates.date.slice(6, 8)}
                            </td>
                          ) : null}
                          <td style={{ fontWeight: "bold" }}>{dates.symbol}</td>

                          <td>{item.title}</td>

                          <td>
                            <FontAwesomeIcon
                              icon={faBell}
                              style={{
                                color: `${
                                  item.importance == 2
                                    ? "gold"
                                    : item.importance == 1
                                    ? "silver"
                                    : "chocolate"
                                }`,
                              }}
                            />
                          </td>
                          <td>{item.fiscal_period}</td>
                          <td>
                            {item.is_audited ? (
                              <FontAwesomeIcon icon={faClipboardCheck} />
                            ) : (
                              <FontAwesomeIcon icon={faCircleXmark} />
                            )}
                          </td>
                          <td>{item.is_combined ? "بله" : "خیر"}</td>
                          <td>
                            {item.value_prev !== null
                              ? item.value_prev.toLocaleString()
                              : ""}
                          </td>

                          <td
                            style={{
                              backgroundColor: `${
                                item.color == 1
                                  ? "green"
                                  : item.color == 0
                                  ? "gray"
                                  : "red"
                              }`,
                              color: "white",
                            }}
                          >
                            {item.value !== null
                              ? item.value.toLocaleString()
                              : ""}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() => toast("دریافت گزارش در دسترس نیست")}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{ color: "#0077b6" }}
                            />
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))
                )
              ) : (
                <tr>
                  <td colSpan={6}>
                    <img src={loadingGif} className="loadingGif" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <h2>تکنیکال</h2>
      {analysis && analysis.length > 0 ? (
        <div className="tablesContainer">
          <table className="commonTable" style={{ color: "black" }}>
            <thead>
              <tr style={{ backgroundColor: "white", color: "black" }}>
                <th>سهم</th>
                <th>RSI</th>
                <th>میانگین متحرک</th>
                <th>خط روند</th>
                <th>مومنتوم</th>
                <th>کندل‌شناسی</th>
                <th>امتیاز</th>
              </tr>
            </thead>
            <tbody>
              {/* analysis.map((item, index) => {
                const dynamicKey = Object.keys(item).find(
                  (key) => key !== "stockTitle" && key !== "rsi" && key !== "ma"
                ); */}

              <tr>
                <td style={{ fontWeight: "bold" }}>{analysis[0].stockTitle}</td>
                <td>
                  {analysis[0].rsi ? (analysis[0].rsi > 0 ? "خوب" : "بد") : "-"}
                </td>
                <td>{analysis[0].ma > 0 ? "خوب" : "بد"}</td>
                <td>
                  {analysis[0].trendline
                    ? analysis[0].trendline > 0
                      ? "خوب"
                      : "بد"
                    : "-"}
                </td>
                <td>
                  {analysis[0].momentum
                    ? analysis[0].momentum > 0
                      ? "خوب"
                      : "بد"
                    : "-"}
                </td>
                <td>
                  {Object.keys(analysis[0]).find(
                    (key) =>
                      key !== "stockTitle" &&
                      key !== "rsi" &&
                      key !== "ma" &&
                      key !== "ma" &&
                      key !== "trendline" &&
                      key !== "sum" &&
                      key !== "momentum"
                  )
                    ? Object.keys(analysis[0]).find(
                        (key) =>
                          key !== "stockTitle" &&
                          key !== "rsi" &&
                          key !== "ma" &&
                          key !== "trendline" &&
                          key !== "sum" &&
                          key !== "momentum"
                      )
                    : "-"}
                </td>
                <td>{analysis[0].sum}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <p>به علت کمبود منابع سرور، تکنیکال این نماد در دسترس نمی‌باشد.</p>
      )}
    </div>
  );
}

export default StockDetails;
