import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import BASE_URL from "../variables.js";

import LoadingGif from "../assets/loading.gif";
import { toast } from "react-toastify";
import BoardFilter from "./boardFilter.jsx";
import Picker from "./picker.jsx";
import moment from "moment-jalaali";

function Tablo() {
  // const [stocks, setStocks] = useState();
  const [wholeBoard, setWholeBoard] = useState(null);

  const [requestedDate, setRequestedDate] = useState();
  const [showDate, setShowDate] = useState("");
  const isFirstRender = useRef(true);

  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/board/lastDate`)
      .then((response) => {
        const formattedDate = response.data.date.replace(
          /(\d{4})(\d{2})(\d{2})/,
          "$1-$2-$3"
        );
        setShowDate(response.data.date);
        setRequestedDate(response.data.date);
        axios
          .get(`${BASE_URL}/api/board/getAll?date=${formattedDate}`)
          .then((response) => {
            setWholeBoard(response.data);
            setLoading(false);
          })
          .catch((error) => {
            // if (error.response.status == 401) {
            //   localStorage.removeItem("userName");
            //   localStorage.removeItem("token");
            //   toast("به دلیل گذشت زمان باید دوباره وارد حساب خود شوید.");
            //   setTimeout(() => {
            //     window.location = "/";
            //   }, 1000);
            // } else {
              toast("مشکلی پیش آمد1. ");
              // toast("در تاریخ انتخاب شده بازار تعطیل می باشد. ");
            // }
          });
      })
      .catch((error) => {
        // if (error.response.status == 401) {
        //   localStorage.removeItem("userName");
        //   localStorage.removeItem("token");
        //   toast("به دلیل گذشت زمان باید دوباره وارد حساب خود شوید.");
        //   setTimeout(() => {
        //     window.location = "/";
        //   }, 1000);
        // } else {
          toast("مشکلی پیش آمد2. ");
          // toast("در تاریخ انتخاب شده بازار تعطیل می باشد. ");
        // }
      });
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (requestedDate) {
      setLoading(true);
      const formattedDate = requestedDate.replace(
        /(\d{4})(\d{2})(\d{2})/,
        "$1-$2-$3"
      );
      axios
        .get(`${BASE_URL}/api/board/getAll?date=${formattedDate}`)
        .then((response) => {
          setWholeBoard(response.data);
          setLoading(false);
        })
        .catch((error) => {
          // if (error.response.status == 401) {
          //   localStorage.removeItem("userName");
          //   localStorage.removeItem("token");
          //   toast("به دلیل گذشت زمان باید دوباره وارد حساب خود شوید.");
          //   setTimeout(() => {
          //     window.location = "/";
          //   }, 1000);
          // } else {
            toast("مشکلی پیش آمد. ");
            setLoading(false);
          // }
        });
    }
  }, [requestedDate]);

  return (
    <div>
      {/* <p
        style={{
          display: "inline",
          fontWeight: "bold",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        تاریخ امتیازدهی:{" "}
        {showDate && `${showDate.slice(0, 4)}/${showDate.slice(4, 6)}/${showDate.slice(6,8)}`}
      </p> */}
      {requestedDate && (
        <div style={{ marginBottom: "30px" }}>
          <Picker currentDate={showDate} setter={setRequestedDate} />
        </div>
      )}
      <BoardFilter
        setter={setWholeBoard}
        date={
          requestedDate &&
          requestedDate.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")
        }
      />

      <hr />
      <div className="tablesContainer">
        <table className="commonTable" id="boardTable">
          <thead>
            <tr style={{ backgroundColor: "red" }}>
              <th>رتبه</th>
              <th>سهم</th>
              <th>حجم مشکوک</th>
              <th>پول هوشمند</th>
              <th>پول حقیقی</th>
              <th>پایانی به آخرین</th>
              <th>قدرت خریدار</th>
              <th>امتیاز</th>
              <th>تغییر روز بعد</th>
              <th>تغییر 2 روز بعد</th>
              <th>تغییر 3 روز بعد</th>
            </tr>
          </thead>
          <tbody>
            {wholeBoard && !loading ? (
              wholeBoard.map((item, index) => (
                <tr
                  key={index}
                  style={
                    item.sum >= 75
                      ? { backgroundColor: "#0B6623" }
                      : item.sum >= 50
                      ? { backgroundColor: "#8cc73c" }
                      : item.sum >= 25
                      ? { backgroundColor: "#87CEEB" }
                      : item.sum >= -25
                      ? { backgroundColor: "#808080" }
                      : item.sum >= -50
                      ? { backgroundColor: "#9b870c" }
                      : item.sum >= -75
                      ? { backgroundColor: "orange" }
                      : { backgroundColor: "red" }
                  }
                >
                  <td style={{ fontWeight: "bold" }}>{item.index}</td>
                  <td
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        `/dashboard/didehban/${item.stockTitle}`,
                        "_blank"
                      );
                    }}
                  >
                    {item.stockTitle}
                  </td>
                  <td>{item.suspicios_volume}</td>
                  <td>{item.intel_money}</td>
                  <td>{item.real_money}</td>
                  <td>{item.final_last}</td>
                  <td>{item.buy_power}</td>
                  <td style={{ fontWeight: "bold" }}>{item.sum}</td>
                  <td style={{ fontWeight: "bold" }}>{item.day_1_change}</td>
                  <td style={{ fontWeight: "bold" }}>{item.day_2_change}</td>
                  <td style={{ fontWeight: "bold" }}>{item.day_3_change}</td>
                </tr>
              ))
            ) : (
              <tr style={{ border: "none" }}>
                <td colSpan={10}>
                  <img src={LoadingGif} className="loadingGif" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Tablo;
